<template>
  <div id="Pgwrp" ref="Pgwrp">
    <div  v-if="!viLoader">
     
      <div class="row">
        <div class="col-md-12 col-lg-12 col-sm-12">
          <div class="widjet m-2">
            <div class="widjethdr dispflex">
              <div style="width: auto">
                <span class="iconsect blubg" style="padding-left: 10px;"><i class="fa fa-bell"></i></span>
                <h1 >VIEW ANNOUNCEMENT</h1>
              </div>

              <div class="mt-1">
                <button
                  class="btn gry_bg tooltipt mpopup twoicn"
                  v-b-modal.crtAlertAndNotifications
                >
               
                  <i class="fas fa-file-invoice user1"></i>
                  <p class="user2"><i class="fas fa-plus"></i></p>
                  <span class="tooltiptext">PUBLISH NEW ANNOUNCEMENT</span>
                </button>
              </div>
            </div>
            <div class="widjetcontent">
              <div class="crtfeeplancont mt-3" style="padding: 0 13px">
                <div class="restable">
                  <div class="resrow resheader">
                    <div class="cell">Subject Name</div>
                    <div class="cell">Event Type</div>
                    <div class="cell">Created Date</div>
                    <div class="cell">Publish Date</div>
                    <div class="cell">Action</div>
                  </div>
                  <div class="resrow" v-for="(plan, index) in alertList" :key="index">
                     
                       <div  data-title="Plan Name" class="cell">
                         {{ plan.subject }}
                       </div>
                       <div   data-title="Plan Name" class="cell">
                        {{ plan.eventType }}
                       </div>
                      
                       <div   data-title="Plan Name" class="cell">
                        {{ plan.createdDate |  dateFormat}}
                       </div>
                       <div   data-title="Plan Name" class="cell">
                        {{ plan.publishDate |  dateFormat}}
                       </div>
                     

                      <div  class="cell" data-title="Action">
                        <div class="dispflex" style="justify-content:stretch">
                          <!-- <b-dropdown id="dropdown-1" text="" right  style="margin-right:3px">
                              <b-dropdown-item>Send</b-dropdown-item>
                          </b-dropdown> -->
                        <button class="btn round orange tooltipt" @click.prevent="editAlert(index)">
                          <i class="fas fa-eye" style="padding-left:5px"></i>
                          <span data-title="Edit" class="tooltiptext">View</span>
                        </button>
                        <button  class="btn round red tooltipt">
                          <i class="far fa-trash-alt"></i>
                          <span class="tooltiptext">Delete</span>
                        </button>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
              <div class="text-center mt-4" v-if="alertList.length > 0">
                <div class="pagination" id="pagination">
                  <a
                    :style="
                      currentPageNumber === 1 ? 'pointer-events: none;' : ''
                    "
                    @click="goToPreviousPage"
                    >&laquo;</a
                  >
                  <!-- class="active"  -->
                  <a
                    v-for="(item, index) in lastPageNumber"
                    :key="index"
                    v-on:click="selected = item"
                    v-bind:class="{ active: selected == item }"
                    @click="navToSelectedPage(item)"
                    >{{ item }}</a
                  >
                  <a
                    :style="
                      currentPageNumber === lastPageNumber
                        ? 'pointer-events: none;'
                        : ''
                    "
                    @click="goToNextPage"
                    >&raquo;</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="crtAlertAndNotifications"
      style="width: 100%"
      no-close-on-backdrop
      no-close-on-esc
    >
    <button type="button" class="close"  @click.prevent="closePoup">×</button>
      <div class="contact-form">
        <h2>{{ "PUBLISH NEW ANNOUNCEMENT" }}</h2>
        <br />
        <div>
          <form
            data-vv-scope="crtfeeTypeValidate"
            id="crtfeeplans"
            accept-charset="utf-8"
          >
          <div class="row">
          <div class="col-lg-6" style="border-right:1px solid #e5e5e5">
            <div class="form-row">
              <div class="form-group input-group col-md-12 mt-3">
                <span class="has-float-label">
                  <v-select
                    class="form-control form-input"
                    label="label"
                    name="AssignClasses"
                    multiple
                    v-model="alertAndNotifications.class"
                    :options="getformatClassList"
                    :reduce="(label) => label.value"
                  ></v-select>
                  <label  for="fpstartdateid"
                    >To Sections</label
                  >
                </span>
              </div>
              <div
                      class="form-group input-group col-md-12 mt-3 pr-md-3"
                      style="border-bottom: 1px solid #e5e5e5"
                    >
                      <span style="color: #6c757d" class="mr-3">
                        All Sections ?
                        <span class="required">*</span></span
                      >
                      <label class="switch">
                        <input
                          @change="setAllClass"
                          type="checkbox"
                          v-model="alertAndNotifications.entireSchool"
                          id="togBtn"
                        />
                        <div class="slider"></div>
                      </label>
              </div>
              <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                <span class="has-float-label">
                   <select
                      class="form-control form-input"
                      name="PlanType"
                      v-model="alertAndNotifications.eventType"
                      >
                      <option :value=null>--Select--</option>
                      <option v-for="(item,index) in eventTypeList" :key="index" :value="item">
                        {{ item }} 
                      </option>
                      </select>

                  <label for="fpnameid"
                    >Select Event Type <span class="required">*</span></label
                  >
                </span>
              </div>
             
              <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                <span class="has-float-label">
                  <input 
                    v-model="alertAndNotifications.publishDate"
                    type="date"
                    class="form-control form-input"
                    id="fpstartdateid"
                  />
                  <label  for="fpstartdateid"
                    >Publish Date</label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-4 mt-3 ">
                <span class="has-float-label">
                  <input 
                    v-model="alertAndNotifications.dueDate"
                    type="date"                   
                    class="form-control form-input"
                    id="fpstartdateid"
                  />
                  <label  for="fpstartdateid"
                    >Due Date</label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-12 mt-3">
                <span class="has-float-label">
                  <input 
                    v-model="alertAndNotifications.subject"
                    type="text"
                    class="form-control form-input"
                    id="fpstartdateid"
                  />
                  <label  for="fpstartdateid"
                    >Subject</label
                  >
                </span>
              </div>
              <div class="form-group col-md-12" style="height:250px">
              <div class=" input-group mt-4">
                  <span class="web-has-float-label">
                    
                     <vue-editor


                        style="height: 162px;"
                        v-model="alertAndNotifications.content"

                      >
                      </vue-editor>

                    <!-- <app-quill-editor

                        :options="editorOption"
                        style="height: 162px;"
                        v-model="alertAndNotifications.content"

                      >
                      </app-quill-editor> -->
                     
                    <label for="message" style="top:-1.5em"
                      >Description<span class="required">*</span></label
                    >
                  </span>
              </div>
              </div>
               </div>
          </div>
          <div class="col-lg-6">
               <div class="form-row">
                  <button 
                      type="button"
                      class="btn btnsml"
                      id="svbtn"
                      @click.prevent="addlink"
                    >
                      add link
                    </button>
              </div>
              <div class="form-row" style="width: 100%;" v-for="(link, index) in alertAndNotifications.linkList" :key="index">
              
              <div class="form-group input-group col-md-5 mt-3 pr-md-3">
                <span class="web-has-float-label">
                  <input placeholder=" "
                    v-model="link.name"
                    class="form-control web-form-input"
                    id="fpstartdateid"
                  />
                  <label  for="fpstartdateid">Link Name</label>
                </span>
              </div>
               <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                <span class="web-has-float-label">
                  <input placeholder=" "
                   v-model="link.value"
                    class="form-control web-form-input"
                    id="fpstartdateid"
                  />
                  <label  for="fpstartdateid">Link</label>
                </span>
              </div>
               <div class="form-group input-group col-md-1 mt-3">
                 <button class="btn round red tooltipt"  @click.prevent="removelink(index)" style="height:1.8rem"><i class="far fa-trash-alt"></i><span class="tooltiptext">Delete</span></button>
                
              </div>
              </div>
              
               <div class="form-row" >
                           <input
                            type="file"
                            id="importExportID"
                            ref="attachDoc"
                            name="attachDoc"
                            accept="application/pdf,application/msword,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,image/jpg, image/png"
                            @change="updateFile('attachDoc')"
                           />
              </div>
              <div class="form-row" style="width: 100%;" v-for="(doc, indexDoc) in alertAndNotifications.documentList" :key="indexDoc">
               <div class="form-group input-group col-md-11 mt-3 pr-md-3">
                <span class="has-float-label">
                  <input 
                    v-model="doc.name"
                    disabled
                    class="form-control form-input"
                    id="fpstartdateid"
                  />
                  <label  for="fpstartdateid">File Name</label>
                </span>
              </div>
               <div class="form-group input-group col-md-1 mt-3 ">
                 <button class="btn round red tooltipt"  @click.prevent="removeDoc(index,doc.url.key)" style="height:1.8rem"><i class="far fa-trash-alt"></i><span class="tooltiptext">Delete</span></button>
               
              </div>
              </div>
          </div>
          </div>
          </form>

          <div class="mt-3 pt-3" style="border-top: 1px solid #e5e5e5">
            <!-- <div class="dk_icon"></div> -->
            <div class="text-center dk_iconsml">
            
              <button  @click.prevent="saveAlert" type="button" class="btn btnsml" id="svbtn">
                  <span>Send</span>
              </button> 
              <button
                @click.prevent="closePoup"
                type="button"
                class="btn btncl clsmdl"
                id="clbtn"
              >
                   <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="viewAlertAndNotifications"
      style="width: 100%"
      no-close-on-backdrop
      no-close-on-esc
    >
    <button type="button" class="close"  @click.prevent="closeViewPoup">×</button>
      <div class="contact-form">
        <h2>{{ "VIEW ANNOUNCEMENT" }}</h2>
        <br />
        <div>
          <form
            data-vv-scope="crtfeeTypeValidate"
            id="crtfeeplans"
            accept-charset="utf-8"
          >
            <div class="form-row">
              <div class="form-group input-group col-md-10 mt-3 pr-md-3">
                <span class="has-float-label">
                  <v-select
                    class="form-control form-input"
                    label="label"
                    name="AssignClasses"
                    multiple
                    disabled
                    v-model="alertAndNotifications.class"
                    :options="getformatClassList"
                    :reduce="(label) => label.value"
                  ></v-select>
                  <label  for="fpstartdateid"
                    >To</label
                  >
                </span>
              </div>
              <div
                      class="form-group input-group col-md-2 mt-3"
                      style="border-bottom: 1px solid #e5e5e5"
                    >
                      <span style="color: #6c757d" class="mr-3">
                        All Classes ?
                        <span class="required">*</span></span
                      >
                      <label class="switch">
                        <input
                        disabled
                          @change="setAllClass"
                          type="checkbox"
                          v-model="alertAndNotifications.entireSchool"
                          id="togBtn"
                        />
                        <div class="slider"></div>
                      </label>
              </div>
              <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                <span class="has-float-label">
                   <select
                   disabled
                      class="form-control form-input"
                      name="PlanType"
                      v-model="alertAndNotifications.eventType"
                      >
                      <option :value=null>--Select--</option>
                      <option v-for="(item,index) in eventTypeList" :key="index" :value="item">
                        {{ item }} 
                      </option>
                      </select>

                  <label for="fpnameid"
                    >Select Event Type <span class="required">*</span></label
                  >
                </span>
              </div>
             
              <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                <span class="has-float-label">
                  <input 
                  disabled
                    v-model="alertAndNotifications.publishDate"
                    type="date"
                    class="form-control form-input"
                    id="fpstartdateid"
                  />
                  <label  for="fpstartdateid"
                    >Publish Date</label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                <span class="has-float-label">
                  <input 
                  disabled
                    v-model="alertAndNotifications.dueDate"
                    type="date"                   
                    class="form-control form-input"
                    id="fpstartdateid"
                  />
                  <label  for="fpstartdateid"
                    >Due Date</label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-12 mt-3 pr-md-3">
                <span class="has-float-label">
                  <input 
                  disabled
                    v-model="alertAndNotifications.subject"
                    type="text"
                    class="form-control form-input"
                    id="fpstartdateid"
                  />
                  <label  for="fpstartdateid"
                    >Subject</label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-12 mt-3 pr-md-3">
                  <span class="web-has-float-label">
                     <!-- <app-quill-editor
                        disabled
                        v-model="alertAndNotifications.content"
                        style="height: 120px;margin-bottom: 4%;"
                      >
                      </app-quill-editor> -->
                      <div
                        disabled
                        v-html="alertAndNotifications.content"
                        style="height: 250px;margin-bottom: 4%;overflow-y: scroll;padding-top: 1%;"
                      >
                      </div> 
                    <label for="message" 
                      >Description<span class="required">*</span></label
                    >
                  </span>
              </div>
               </div>
               <div class="form-row" >
                  <!-- <button 
                      type="button"
                      class="btn btnsml"
                      id="svbtn"
                    >
                      add link
                    </button> -->
              </div>
              <div class="form-row" style="width: 100%;" v-for="(link, index) in alertAndNotifications.linkList" :key="index">
              <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                <span class="has-float-label">
                  <input 
                  disabled
                    v-model="link.name"
                    class="form-control form-input"
                    id="fpstartdateid"
                  />
                  <label  for="fpstartdateid">Link Name</label>
                </span>
              </div>
               <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                <span class="has-float-label">
                  <input 
                   v-model="link.value"
                   disabled
                    class="form-control form-input"
                    id="fpstartdateid"
                  />
                  <label  for="fpstartdateid">Link</label>
                </span>
              </div>
              
              </div>
              <div class="form-row" style="width: 100%;" v-for="(doc, indexDoc) in alertAndNotifications.documentList" :key="indexDoc">
               <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                <span class="has-float-label">
                  <input 
                    v-model="doc.name"
                    disabled
                    class="form-control form-input"
                    id="fpstartdateid"
                  />
                  <label  for="fpstartdateid">File Name</label>
                </span>
              </div>
               <div class="form-group input-group col-md-4 mt-3 pr-md-3">

                 <button 
                      type="button"
                      class="btn btnsml"
                      id="svbtn"
                      @click.prevent="viewDoc(doc.url)"
                    >
                      Download
                    </button>
              </div>
              </div>
           

          </form>

          <div class="mt-3 pt-3" style="border-top: 1px solid #e5e5e5">
            <!-- <div class="dk_icon"></div> -->
            <div class="text-center dk_iconsml">
            
             
              <button
                @click.prevent="closeViewPoup"
                type="button"
                class="btn btncl clsmdl"
                id="clbtn"
              >
               Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <vi-spinner
                v-if="viLoader"
                text="Loading..."
                textColor="vi-brand-color"
                class="flex-fill h-100 vi-fs12"
                style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
              />
  </div>
</template>
<script>
import ViSpinner from "../Common/ViSpinner";
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";
import "vue-select/dist/vue-select.css";
import Select from "vue-select";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { VueEditor } from "vue2-editor";

import { quillEditor } from "vue-quill-editor";

export default {
  name: "vi-adhocFees",
  data() {
    return {
      viLoader: false,
      searchWords: "",
      skip: 0, 
      limit: 8, 
      currentPageNumber: 1,
      lastPageNumber: 1,
      selected: 1,
      countAll: true,
      totalCount: 0,
      alertList: [],
      editId: null,
      classList: [],
      sectionList: [],
      eventTypeList: [
        'General',
        'Holiday',
        'Exam Scheduled',
        'Events',
        'Parents-Teacher Meet',
        'Announcement'
        
      ],
     editorOption: {
    placeholder:'Please enter it here...',
    disabled: true,
    modules:{
        toolbar:{
		    container: [
			    ['bold','italic','underline','strike'],
				['blockquote'],
				[{'header': 1 }, {'header': 2 }],
				[{'list':'ordered'}, {'list':'bullet' }],
				[{'indent':'-1'}, {'indent':'+1' }],
				[{'direction':'rtl' }],
				[{'size': ['small', false,'large','huge'] }],
				// [{'header': [1, 2, 3, 4, 5, 6, false] }],
				[{'color': [] }, {'background': [] }],
				[{'font': [] }],
				[{'align': [] }],
        ['link']
			],
		
	    }
    }
},
      alertAndNotifications: {
        entireSchool: false,
        class: [],
        sectionIds: [],
        subject: '',
        eventType: '',
        publishDate: null,
        dueDate: null,
        content: '',
        documentLink: '',
        documentList: [],
        linkList: [{
          name: '',
          value: ''
        }]
      },
    };
  },
  created() {
    this.getClassWithSection()
    //this.getClassList()
    this.getAlertList()
  },
  computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 90 + "px",
      };
    },
    getformatClassList() {
      if (this.classList.length > 0) {
        return this.classList.map((x) => {
          return {
            label: x.className + " - " + x.sectionName,
            value: x.section,
          };
        });
      } else {
        return [];
      }
    },
  },
  watch: {
    searchWords: function (currentVal, oldVal) {
      let hasCurrentParam = currentVal || "";
      let hasOldParam = oldVal || "";

      if (hasCurrentParam != hasOldParam) {
        this.skip = 0;
        (this.currentPageNumber = 1),
          (this.lastPageNumber = 1),
          (this.selected = 1),
          (this.totalCount = 0),
          (this.countAll = true);
      }
    },
  },
  methods: {
    previousRoute(){
     let data = localStorage.getItem('previousTab');
     
     localStorage.setItem("activeTab",data);
     localStorage.setItem("previousTab",this.$route.path);
     this.$router.push({ path: data });
    },
    async navToSelectedPage(selectedPageNo) {
      this.skip = this.limit * (selectedPageNo - 1);

      this.countAll = false;
      this.getAlertList()
    },
    getSection(classId) {
      this.selectedSection = null
      this.assignedSubjects = []
      let tempSections = []
    //  const letters = new Set();
      let allSections = this.classList.find((x) => x._id == classId).sections;

      let userData = secureUI.sessionGet("user");
      

      if( userData.userDetails.profile.name == "Teacher"){
        let sections = allSections.filter( x => x.assignedTeachers.length > 0)

        
        if(sections.length > 0){
          for(let x of sections){
            for(let y of x.assignedTeachers){
              if(y.teacherId && y.teacherId == userData.userDetails._id){
               // this.assignedSubjects.push(y.subjectId)
                tempSections.push(x._id)

              }
            }
          }
          // Create a Set from the array to remove duplicates
          let uniqueSet = new Set(tempSections);

          // Convert the Set back to an array
          let uniqueArray = Array.from(uniqueSet);

          //sections

          this.sectionList = sections.filter((s) => uniqueArray.includes(s._id));

          this.selectedSection = sections.length > 0 ? sections[0]._id : null
       }
     
      
      //  assignedSubjects
      }
      else{
        this.sectionList = allSections

        this.selectedSection = this.sectionList.length > 0 ? this.sectionList[0]._id : null

       }
       //this.checkValueChanged()
    },
    
    async getClassWithSection() {
      let tempClassList = []
      let tempSections = []

      let sectionids = []

      let userData = secureUI.sessionGet("user");

       if( userData.userDetails.profile.name == "Teacher"){

        const response = await ViService.viXGet("/teacher/getAssignedTeacherwithClassSectionList?viewType=list",userData.token);

        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);
      
          this.$store.dispatch("setClassAndSectionList", resultData.data);
          tempClassList = resultData.data;
        } else {
          this.$toasted.error(response.message);
        }

       }

       if(tempClassList.length > 0){
        // if(sections.length > 0){
          for(let x of tempClassList){
            for(let y of x.sections){
              for(let z of y.assignedTeachers){
                if (sectionids.indexOf(y._id.toString()) == -1) {
                if(z.teacherId && z.teacherId == userData.userDetails._id){
                  tempSections.push({ 
                    class: x._id,
                    section: y._id,
                    className: x.className,
                    sectionName: y.name
                  })

                  sectionids.push(y._id.toString())

                }
              }
              }
            }
          }
          // let uniqueSet = new Set(tempSections);

          // let uniqueArray = Array.from(uniqueSet);

          // //sections
          this.classList = tempSections
          // this.sectionList = sections.filter((s) => uniqueArray.includes(s._id));

          // this.selectedSection = sections.length > 0 ? sections[0]._id : null
        
       }

     
     
    },
    addlink(){
       this.alertAndNotifications.linkList.push({ name: '', value: ''})
    }, 
    viewDoc(url){
      window.open(url, "_blank");
    },
    removelink(index){
     this.alertAndNotifications.linkList.splice(index,1);
    },
    async removeDoc(index,key){
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        this.alertAndNotifications.documentList.splice(index,1);
      //   const response = await ViService.viDelete(
      //     `/document/removeAlertFile/${key}`,
      //     userData.token
      //   );
        
      //   if (response.isSuccess) {
      //     this.alertAndNotifications.documentList.splice(index,1);
      //   } else {
      //     this.$toasted.error(response.message);
      //      if(response.message == 'Your session has expired, please login'){
      //           localStorage.removeItem('user');
      //           this.$router.push('/login');
      //    }
      //   }
       }
    },
    
    async goToNextPage() {
      this.countAll = false;
      this.currentPageNumber = this.currentPageNumber + 1;
      this.selected = this.currentPageNumber;
      this.skip = this.limit * (this.currentPageNumber - 1);

      this.getAlertList()
    },

    async goToPreviousPage() {
      this.countAll = false;
      this.currentPageNumber = this.currentPageNumber - 1;
      this.selected = this.currentPageNumber;
      this.skip = this.limit * (this.currentPageNumber - 1);
     this.getAlertList()
    },
    async updateFile(docType) {
       let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const docFileDetails = this.$refs[docType].files[0]
        if(docFileDetails){

          const fd = new FormData();

          fd.append(
            "doc",
            docFileDetails,
            docFileDetails.name,
            docFileDetails.actType
          );

          const response = await ViService.viPutOnly(
            `/document/uploadFile/alertAttachment`,
            fd,
            userData.token
          );
          
          if(response.isSuccess){

            this.alertAndNotifications.documentList.push({
              type: docFileDetails.type,
              name: docFileDetails.name,
              content: response.value.base64,
              url: response.value.url
            })

          }else{
            this.$toasted.error(base64.message);
          }

        }
        
        document.getElementById("importExportID").value=null; 

      }

    },
    convertBase64(file){
      return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);

          fileReader.onload = () => {
              resolve({ isSuccess: true,data: fileReader.result.substr(fileReader.result.indexOf(',') + 1) })

          };

          fileReader.onerror = (error) => {
            reject({ isSuccess: false, message: error })
          };
      });
    },
    setAllClass(){

      this.alertAndNotifications.class = []


      if(this.alertAndNotifications.entireSchool){
         this.alertAndNotifications.class = this.classList.map( x => x.section)
      }
    
    },
    // async getClassList() {
    //   let userData = secureUI.sessionGet("user");

    //   if (!userData) {
    //     this.$toasted.error("Please login and do the action");
    //     this.$router.push('/login');
    //   } else {
    //     this.classList = []
    //     const response = await ViService.viXGet(
    //       "/contact/getClassList",
    //       userData.token
    //     );
    //     if (response.isSuccess) {
    //       this.classList = secureUI.secureGet(response.data);
    //       this.$toasted.success(response.message);
    //     } else {
    //       this.$toasted.error(response.message);
    //        if(response.message == 'Your session has expired, please login'){
    //             localStorage.removeItem('user');
    //             this.$router.push('/login');
    //      }
    //     }
    //   }
    // },
    async getAlertList() {
      
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
         this.viLoader = true;

        const response = await ViService.viXGet(
          `/contact/getTeacherCreatedAlertList?skip=${this.skip}&limit=${this.limit}&isCount=${this.countAll}`,
          userData.token
        );
        if (response.isSuccess) {
           const resultData = secureUI.secureGet(response.data);

           this.alertList = resultData.alertList

           if(this.countAll){
            this.totalCount = resultData.totalCount
            this.lastPageNumber = Math.ceil(this.totalCount.length/this.limit);
           }

        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
         this.viLoader = false;
      }
    },
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
    closePoup() {
      this.$bvModal.hide("crtAlertAndNotifications");
        this.alertAndNotifications.sectionIds = [],
        this.alertAndNotifications.entireSchool = false,
        this.alertAndNotifications.class = [],
        this.alertAndNotifications.subject = '',
        this.alertAndNotifications.eventType = '',
        this.alertAndNotifications.publishDate = null,
        this.alertAndNotifications.dueDate = null,
        this.alertAndNotifications.content = '',
        this.alertAndNotifications.documentLink = '',
        this.alertAndNotifications.documentList = [],
        this.alertAndNotifications.linkList = [{
          name: '',
          value: ''
        }]

      this.editId = null;
    },
    closeViewPoup() {
      this.$bvModal.hide("viewAlertAndNotifications");
      this.closePoup()
    },
    editAlert(index){
        const details = this.alertList[index]
      
        this.alertAndNotifications.entireSchool = details.entireSchool,
        this.alertAndNotifications.class = details.sectionIds,
        this.alertAndNotifications.subject = details.subject,
        this.alertAndNotifications.eventType = details.eventType,
        this.alertAndNotifications.publishDate = details.publishDate,
        this.alertAndNotifications.dueDate = details.dueDate,
        this.alertAndNotifications.content = details.content,
        this.alertAndNotifications.documentList = details.documentList
        this.alertAndNotifications.linkList = details.linkList 
        this.$bvModal.show("viewAlertAndNotifications");
      
    },
    async saveAlert(){

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {

         let sectionList = this.classList.filter((s) => this.alertAndNotifications.class.includes(s.section));

         let cloneclass = sectionList.map( x => x.class)

         const uniqueArray = [...new Set(cloneclass)];

         this.alertAndNotifications.class = uniqueArray;

         this.alertAndNotifications.sectionIds = sectionList.map( x => x.section);

         var newDate = new Date();
          var hour = newDate.getHours() < 10 ? "0" + newDate.getHours() : newDate.getHours();
          var minute = newDate.getMinutes() < 10 ? "0" + newDate.getMinutes() : newDate.getMinutes();
          var second = newDate.getSeconds() < 10 ? "0" + newDate.getSeconds() : newDate.getSeconds();
          var meridian = newDate.getHours() < 12 ? "AM" : "PM";

          // this.viLoader = true;

          this.alertAndNotifications['createdTimeValue'] = hour + ":" + minute + " " + meridian;
          

        const response = await ViService.viXPost(
          "/contact/teacher/student/alertAndNotification",
          this.alertAndNotifications,
          userData.token
        );

        if (response.isSuccess) {
          this.closePoup()
          this.getAlertList()
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    }
  },
  components: {
    "v-select": Select,
    "app-quill-editor": quillEditor,
    "vue-editor": VueEditor,
    ViSpinner

  },
};
</script>
